import { render, staticRenderFns } from "./polkadot2024.vue?vue&type=template&id=70f2b126&scoped=true&"
import script from "./polkadot2024.vue?vue&type=script&lang=js&"
export * from "./polkadot2024.vue?vue&type=script&lang=js&"
import style0 from "./polkadot2024.vue?vue&type=style&index=0&id=70f2b126&prod&scoped=true&lang=css&"
import style1 from "./polkadot2024.vue?vue&type=style&index=1&id=70f2b126&prod&scoped=true&lang=css&"
import style2 from "./polkadot2024.vue?vue&type=style&index=2&id=70f2b126&prod&scoped=true&lang=css&"
import style3 from "./polkadot2024.vue?vue&type=style&index=3&id=70f2b126&prod&scoped=true&lang=css&"
import style4 from "./polkadot2024.vue?vue&type=style&index=4&id=70f2b126&prod&scoped=true&lang=css&"
import style5 from "./polkadot2024.vue?vue&type=style&index=5&id=70f2b126&prod&scoped=true&lang=css&"
import style6 from "./polkadot2024.vue?vue&type=style&index=6&id=70f2b126&prod&scoped=true&lang=css&"
import style7 from "./polkadot2024.vue?vue&type=style&index=7&id=70f2b126&prod&scoped=true&lang=css&"
import style8 from "./polkadot2024.vue?vue&type=style&index=8&id=70f2b126&prod&scoped=true&lang=css&"
import style9 from "./polkadot2024.vue?vue&type=style&index=9&id=70f2b126&prod&scoped=true&lang=css&"
import style10 from "./polkadot2024.vue?vue&type=style&index=10&id=70f2b126&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70f2b126",
  null
  
)

export default component.exports